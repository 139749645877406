<template>
  <div class="pyaments-client-details">
    <div v-loading="is_panding" class="wrapper">
        <div class="header">
            <h3>לקוח: {{client_payment_data.client_name}}, מס:{{client_payment_data.client_num}}</h3>
            {{calculate_itrat_client}}
            <div @click="client_payment_data = null; $emit('back_to_select_client')" class="back-btn">
                <i class="material-icons">arrow_back_ios_new</i>
            </div>
        </div>
        <div class="payments-list">
            <div class="sign-all-payments">
                <div class="check-box">
                    <!-- <input type="checkbox" @change="updateSelectedPayments(payment)"> -->
                    <div  @click="handle_sign_all_payments" v-if="is_sign_all"  class="mark-sign marked">
                        <i class="material-icons">done</i>
                    </div>
                    <div v-else @click="handle_sign_all_payments(false)"  class="mark-sign"></div>
                </div>
            </div>
            <template v-for="payment in client_payment_data.client_payments_data" :key="payment.FNCIREF1">
                <div class="payment">
                    <div class="check-box">
                        <!-- <input type="checkbox" @change="updateSelectedPayments(payment)"> -->
                        <div v-if="payment.checked" @click="updateSelectedPayments(payment)" class="mark-sign marked">
                            <i class="material-icons">done</i>
                        </div>
                        <div v-else @click="updateSelectedPayments(payment)" class="mark-sign"></div>
                    </div>
                    <div class="details">
                        <span style="color:grey; font-weight:300;">{{payment.IVNUM}}</span>
                        <p>תאריך <span style="color:blue; font-weight:400;">{{formatDate(payment.BALDATE)}}</span> ת.פרעון: <span style="color:red; font-weight:400;">{{formatDate(payment.FNCDATE)}}</span></p>
                        <p>סכום <span style="color:blue; font-weight:400;" v-if="payment.SUMDEBIT - payment.SUMCREDIT < 0" class="negative-number">-{{ Math.abs(payment.SUMDEBIT + payment.SUMCREDIT) }}</span><span style="color:red; font-weight:400;" v-else>{{ payment.SUMDEBIT + payment.SUMCREDIT }}</span> <span>{{payment.DETAILS}}</span></p>
                    </div>
                </div>
            </template>
        </div>
        <div class="payment-amount">
            <p>
                <span style="font-weight:400;">סכום לתשלום:</span> <span v-if="calculate_payment_amount<=0" style="color:red;">אין סכום לתשלום</span> <span v-else style="color:red; font-weight:400;">{{calculate_payment_amount}} &#8362;</span>
            </p>
            <button v-if="role>99" @click="handle_manual_amount" class="manual-amount">הקש סכום ידני</button>
        </div>
        <button v-if="calculate_payment_amount<=0" disabled class="arrow-button muted-button">
            <i class="material-icons" style="font-size:30px; margin: 0 5px;">credit_card</i>
            <span>עבור לתשלום</span>  
        </button>
        <button v-else @click="handle_move_to_slika"  class="arrow-button">
            <i class="material-icons" style="font-size:30px; margin: 0 5px;">credit_card</i>
            <span >עבור לתשלום</span>  
        </button>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref } from 'vue';
import {client_payment_data} from './Store'
import {slide_pop_error} from '../../../Methods/Msgs'
import Swal from 'sweetalert2'
import { projectFunctions } from '../../../firebase/config';
import {role} from '../../../Methods/Utils'
export default {
    setup(){

        const is_panding = ref(false)

        const is_sign_all = ref(false)

        const selectedPayments = ref([]);

        const calculate_payment_amount = ref(computed(() => {
            let sum = 0
            selectedPayments.value.forEach(payment => {
                sum+=payment.SUMDEBIT - payment.SUMCREDIT
            })

            return sum.toFixed('2')
        }))

        const calculate_itrat_client = ref(computed(() => {
            let sum = 0
            client_payment_data.value.client_payments_data.forEach(payment => {
                sum+=payment.SUMDEBIT - payment.SUMCREDIT
            })

            const res = Number(sum) - Number(calculate_payment_amount.value)
            return res.toFixed('2')
        }))

        const handle_sign_all_payments = (turn_off) => {
            if(turn_off) is_sign_all.value = false
            else{
                is_sign_all.value = !is_sign_all.value
            }

            if(is_sign_all.value){
                selectedPayments.value = JSON.parse(JSON.stringify(client_payment_data.value.client_payments_data));
                client_payment_data.value.client_payments_data.forEach(payment => payment.checked = true)
            }else{
                selectedPayments.value = []
                client_payment_data.value.client_payments_data.forEach(payment => payment.checked = false)
            }
        }

        const updateSelectedPayments = (payment) => {
            payment.checked = !payment.checked
            if (payment.checked) {
                selectedPayments.value.push(payment);
            } else {
                const index = selectedPayments.value.findIndex(({IVNUM}) => IVNUM == payment.IVNUM);
                if (index !== -1) {
                    selectedPayments.value.splice(index, 1);
                }
            }
        };
        
        const handle_move_to_slika = async() => {
            try{
                delete client_payment_data.value.manual_amount
                client_payment_data.value.selected_payments = selectedPayments.value
                client_payment_data.value.amount = calculate_payment_amount.value
                client_payment_data.value.itra = calculate_itrat_client.value
                is_panding.value = true
                const clone_client_payment_data = JSON.parse(JSON.stringify(client_payment_data.value));
                delete clone_client_payment_data.contact_data
                delete clone_client_payment_data.client_payments_data
                const result = await projectFunctions.httpsCallable('generate_payment_link_for_agent')(clone_client_payment_data)
                is_panding.value = false
                window.open(result.data,"_self")
            }catch(err){
                is_panding.value = false
                slide_pop_error(err.message)
            }
        }

        const handle_manual_amount = async() => {
            handle_sign_all_payments(true)
            const { value: amount } = await Swal.fire({
                title: 'הכנס סכום ידנית',
                input: 'number',
                confirmButtonColor:'#007BFF',
                cancelButtonColor:'red',
                inputPlaceholder: 'סכום',
                confirmButtonText: 'עבור לתשלום', // Text for the confirm button
                cancelButtonText: 'ביטול', // Text for the cancel button
                showCancelButton: true // Displays the cancel button
            });

            if (amount) {
                try{
                    const clone_client_payment_data = JSON.parse(JSON.stringify(client_payment_data.value));
                    delete clone_client_payment_data.selected_payments 
                    delete clone_client_payment_data.client_payments_data 
                    delete clone_client_payment_data.contact_data
                    clone_client_payment_data.amount = amount
                    is_panding.value = true
                    const result = await projectFunctions.httpsCallable('generate_payment_link_for_agent')(clone_client_payment_data)
                    is_panding.value = false
                    window.open(result.data,"_self")
                }catch(err){
                    is_panding.value = false
                    slide_pop_error(err.message)
                }
            }
        }

        function formatDate(inputStr) {
            const date = new Date(inputStr);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        onBeforeUnmount(()=>{
            client_payment_data.value = null
        })
        
        return{
            is_panding,
            formatDate,
            updateSelectedPayments,
            handle_sign_all_payments,
            handle_manual_amount,
            client_payment_data,
            selectedPayments,
            calculate_payment_amount,
            is_sign_all,
            handle_move_to_slika,
            calculate_itrat_client,
            role
        }
    }
}
</script>

<style scoped>
    .pyaments-client-details{
        width: 100%;
        height: 100%;
        color: #333;
    }
    .wrapper{
        width: 100%;
        max-width: 500px;
        height: 100%;
        background: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
    .wrapper > .header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightgrey;
    }
    
    .negative-number {
        direction: ltr;
        unicode-bidi: embed;
    }
    .arrow-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border: none;
        background-color: #007BFF;
        color: white;
        font-size: 20px;
        cursor: pointer;
        text-align: center;
        box-shadow: 0px 40px 60px rgba(0, 0, 0, 1.8);
    }
    .wrapper > .payments-list > .payment{
        width: 100%;
        height: 60px;
        display: flex;
        border-bottom: 1px solid lightgrey;
    }
    .wrapper > .payments-list > .payment > .check-box, .sign-all-payments > .check-box{
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mark-sign{
        width: 30px;
        height: 30px;
        border: 1px solid lightgray;
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mark-sign.marked{
        color: var(--success);
        font-size: 18px;
    }
    .wrapper > .payments-list > .payment > .details{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .payment-amount{
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        padding: 0 5px;
        align-items: center;
        box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    }
    .sign-all-payments{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
       
    }
    .payments-list{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }
    .arrow-button:hover {
        font-size: 21px;
    }
    .manual-amount{
        padding: 2px 5px;
        border: 0;
        user-select: none;
        border-radius: 5px;
        background: var(--blue);
        color: #fff;
        cursor: pointer;
    }
     .back-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        right: 5px;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        background: #007BFF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #fff;
    }
    .muted-button {
        background-color: #f7f7f7;  /* Light grey background */
        color: #888;                /* Dark grey text */
        border: 1px solid #e0e0e0;  /* Light grey border */
        padding: 10px 15px;         /* Padding for button size */
        font-size: 16px;            /* Font size */
        border-radius: 4px;         /* Rounded corners */
        cursor: not-allowed;            /* Cursor style */
        transition: background-color 0.3s; /* Smooth transition for hover effect */
        background-color: #e0e0e0;
    }

    .muted-button:hover {
        background-color: #e0e0e0;  /* Slightly darker background on hover */
        font-size: 16px;
    }

   :global(.swal2-input[type=number]) {
        max-width: unset; 
    }



</style>